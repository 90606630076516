import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {
  "path": "/developer/ext-js-developing-responsive-applications",
  "date": "2014-07-26",
  "title": "EXT JS DEVELOPING RESPONSIVE APPLICATIONS",
  "author": "admin",
  "tags": ["development", "javascript", "ext.js"],
  "featuredImage": "feature.jpg",
  "excerpt": "Creating responsive web applications have been made easier with the arrival of the new JavaScript Framework Ext JS 5. In this article let's look at how we can use responsiveConfig to implement this feature."
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`Introducing responsiveConfig`}</h2>
    <p>{`The powerful new feature `}<strong parentName="p">{`responsiveConfig`}</strong>{`, for tablets, makes applications respond dynamically to changes in screen size or orientation. responsiveConfig is enabled using one of two classes:`}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Ext.plugin.Responsive`}</strong>{`: Adds responsive capabilities to an Ext.Component`}</li>
      <li parentName="ul"><strong parentName="li">{`Ext.mixin.Responsive`}</strong>{`: Adds responsive capabilities to any other class`}</li>
    </ul>
    <h2>{`Making Components Responsive`}</h2>
    <p>{`To make a Component responsive you need to use the responsive plugin, because Ext JS Components' responsive features are not enabled by default for performance reasons. So you have to do one of the following: `}</p>
    <ul>
      <li parentName="ul">{`Add the responsive plugin to the class body to make all instances responsive, or`}</li>
      <li parentName="ul">{`Add it to the instance config to enable responsiveness for a single Component instance:`}</li>
    </ul>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`plugins 'responsive'
`}</code></pre>
    <p>{`Your Component will gain a `}<strong parentName="p">{`responsiveConfig`}</strong>{` configuration option, when you have added the responsive plugin to your Component config. `}<strong parentName="p">{`responsiveConfig`}</strong>{` is simply an object with keys that represent conditions under which certain configs will be applied.
Let's consider the following scenario: `}</p>
    <p>{`In you application Tab Panel, to dynamically set the Panel’s `}<inlineCode parentName="p">{`tabPosition`}</inlineCode>{` config in response to device orientation change`}</p>
    <table>
    <tr><th>Alignment</th><th>Device Orientation Mode</th><th>responsiveConfig Entry</th></tr>
    <tr><td>Left aligned</td><td>landscape</td><td>landscape</td></tr>
    <tr><td>Top aligned</td><td>landscape</td><td>portrait</td></tr>
    </table>
    <p>{`Moving to code:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`Ext.define('MyApp.view.Main', {
    extend: 'Ext.tab.Panel',
    plugins: 'responsive',
    responsiveConfig: {
        landscape: {
            tabPosition: 'left'
        },
        portrait: {
            tabPosition: 'top'
        }
    },
    items: [
        { title: 'Foo' },
        { title: 'Bar' }
    ]
});
`}</code></pre>
    <h2>{`Rules`}</h2>
    <p>{`Each key, or `}<strong parentName="p">{`rule`}</strong>{`, in the responsiveConfig object is a simple JavaScript expression. The following variables are available for use in responsiveConfig rules:`}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`landscape`}</strong>{` True if the device orientation is landscape (always ‘true’ on desktop devices)`}</li>
      <li parentName="ul"><strong parentName="li">{`portrait`}</strong>{` True if the device orientation is portrait (always ‘false’ on desktop devices)`}</li>
      <li parentName="ul"><strong parentName="li">{`tall`}</strong>{` True if ‘width’ is less than ‘height’ regardless of device type`}</li>
      <li parentName="ul"><strong parentName="li">{`wide`}</strong>{` True if ‘width’ is greater than ‘height’ regardless of device type`}</li>
      <li parentName="ul"><strong parentName="li">{`width`}</strong>{` The width of the viewport`}</li>
      <li parentName="ul"><strong parentName="li">{`height`}</strong>{` The height of the viewport`}</li>
    </ul>
    <p>{`To create complex responsive rules, you can combine these variables in a variety of ways . For example, the following responsiveConfig hides a Component if the viewport is less than 800 pixels wide and the viewport’s height is greater than its width:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`responsiveConfig: {
    'width < 800 && tall': {
        visible: true
    },
    'width >= 800': {
        visible: false
    }
}
`}</code></pre>
    <h2>{`Which Configs Can Be Responsive?`}</h2>
    <p>{`Internally, the framework monitors the viewport for resize and orientation change, and it re-evaluates all of the responsive rules whenever either one of these events occurs. Any matching rules will have setters called for all of their configs. This means that for a configuration option to be used with responsiveConfig, it must have a setter. In the above example, we can use `}<strong parentName="p">{`visible`}</strong>{` as a responsiveConfig because Ext.Component has a setVisible() method.`}</p>
    <h2>{`Making Classes Responsive`}</h2>
    <p>{`responsiveConfig is most useful for Components, but sometimes you may find the need to make other classes respond to screen size as well. For classes other than Ext.Component, this is accomplished by mixing in Ext.mixin.Responsive. For example, an instance of the following class will have its `}<strong parentName="p">{`foo`}</strong>{` config updated whenever the screen shape changes from `}<strong parentName="p">{`wide`}</strong>{` to `}<strong parentName="p">{`tall`}</strong>{` or vice versa:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`Ext.define('MyClass', {
    mixins: ['Ext.mixin.Responsive'],
    config: {
        foo: null
    },
    responsiveConfig: {
        wide: {
            foo: 1
        },
        tall: {
            foo: 2
        }
    },
    constructor: function(config) {
        this.initConfig(config);
    },
    updateFoo: function(foo) {
        console.log(foo); // logs "1" or "2" as screen shape changes between wide and tall
    }
});
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      